<script>
	import { base } from "$app/paths";

	import LL from "@edible/shared/i18n-svelte";
</script>

<div class="h-screen">
	<div class="flex h-full">
		<div class="mx-auto my-auto flex w-3/5 flex-col items-center gap-y-8 md:w-2/5">
			<img src={`${base}/logo.svg`} alt="Edible logo" />
			<div class="mx-2">
				<h1 class="text-center text-xl text-gray-700">
					{$LL.menu.meta.page_description_default()}
				</h1>
			</div>
			<a
				href="https://whatisedible.com"
				class="block rounded-md bg-cherry p-3 font-semibold text-white"
			>
				{$LL.menu.meta.page_title_default()}
			</a>
		</div>
	</div>
</div>
